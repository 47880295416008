.card {
	border-radius: 0;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
	.card-header {
		background-color: white;
		background-color: transparentize($color: #000000, $amount: 0.99);
	}
}

input:not([type="radio"]),
button,
select {
	border-radius: 0 !important;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

nav {
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.help-modal {
	overflow: auto;
	padding: 2rem 0rem;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 15;
	min-width: 100vw;
	min-height: 100vh;
	.backdrop {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
		background: transparentize($color: black, $amount: 0.3);
		width: 100vw;
		min-height: 100vh;
		cursor: pointer;
	}

	.main-help {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 100vw;
		min-height: 100vh;
	}

	.modal-btns {
		position: fixed;
		top: 15px;
		right: 50px;
		display: flex;
		justify-content: flex-end;
		// width: 400px;
		z-index: 5;
	}
}

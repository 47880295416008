// $dark_bg: #1b132c;
// $dark_bg: #1a2c50;
$dark_bg: #000000;
$dark_bg_secondary: lighten($dark_bg, 15);
// $dark_bg_secondary:rgba(0, 0, 0, 0.074);
$th_bg: rgba(85, 68, 153, 0.5);
// $th_bg:darkslateblue;
.dark {
	// background: lighten($color: $dark_bg, $amount: 10);
	background: $dark_bg;
	// *{
	// background: $dark_bg_secondary;
	// }
	color: white;
	table,
	input,
	select {
		background: $dark_bg_secondary;
		color: white;
	}
	table {
		input {
			background: unset;
		}
		th {
			// background: $th_bg;
		}
		tr:hover {
			color: white;
		}
	}

	input,
	select {
		&:hover,
		&:active,
		&:focus {
			background: $dark_bg_secondary;
			color: white;
		}
	}

	// .btn{
	//     // filter: invert(.5);
	// }
}

// .light {
//   background: white;
//   color: black;
//   // table,input,select{
//   //     background: white;
//   //     color: black;
//   // }
// }
// .unset{

// }

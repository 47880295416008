table {
	* {
		border: none;
	}
	th {
		// color: white;
		background: #587eb6;
		font-weight: bold;
		font-size: 1rem;
		text-align: left;
		vertical-align: top !important;
		color: white;
		// border-radius: 10px;
	}
	input {
		background: none;
	}
}

.pagination.disable {
	pointer-events: none;
	filter: opacity(0.5);
}

$animation-duration: 0.3s;
@keyframes slide-up {
	0% {
		bottom: -100vh;
		opacity: 0.2;
	}
	100% {
		bottom: 0;
		opacity: 1;
	}
}
@keyframes slide-right {
	0% {
		left: -100%;
		opacity: 0.2;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}

.store-card {
	opacity: 0;
	position: relative;
	bottom: -100%;
	animation: slide-right $animation-duration forwards;
}

.flex-space-between {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

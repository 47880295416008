$nav-height: 64px;
$grey90: transparentize(black, 0.9);
$input-width: 325px;
#main {
	display: block;
	padding: 0rem 2rem 0rem 2rem;
	min-height: calc(100vh - #{$nav-height});
	background: linear-gradient(360deg, transparentize(rgb(93, 142, 195), 0.9), transparentize(black, 1));
	// background: linear-gradient(45deg, transparentize(#4b8dde, 0.5), transparentize(#4b8dde, .9));

	.menu {
		.menu-form {
			display: flex;
			flex-direction: row;
			width: fit-content;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;
			padding: 0.5rem 1rem;
			.form-group {
				height: 100%;
			}
			.search-toggle-option {
				display: flex;
				flex-direction: row;
			}
			label {
				margin: 0 0.5rem 0 0;
				font-size: 1.05em;
				// font-weight: bold;
				&::after {
					content: ":";
				}
				&.no-after::after {
					content: "";
				}
			}
			input[type="text"],
			select {
				background: white;
				border: unset;
				height: 30px;
				&:hover,
				&:active,
				&:focus {
					box-shadow: 1px 1px black;
					color: black;
				}
			}
			input[type="text"] {
				border: 1px solid grey;
				background: white;
			}
			select {
				width: 125px;
				border: 1px solid grey;
			}
			.w-300 {
				width: $input-width;
			}
		}
	}
}

.p-relative {
	position: relative;
}

.live-search-result {
	position: absolute;
	top: 35px;
	left: 0;
	display: flex;
	flex-direction: column;
	// above loader
	z-index: 11;
	background: white;
	width: $input-width;
	border: 1px black solid;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 200px;
	.search-result {
		white-space: nowrap;
		font-weight: 400;
		border-bottom: 1px solid transparentize($color: black, $amount: 0.8);
		cursor: pointer;
		transition: 0.3s;
		&:hover {
			background: transparentize($color: black, $amount: 0.9);
		}
	}
}

.live-search-indiacator {
	$size: 5px;
	width: $size;
	height: $size;
	border-radius: 50%;
	background-color: grey;
	place-self: flex-end;
	&.live {
		background-color: limegreen;
	}
	&.offline {
		background-color: tomato;
	}
}

.highlight {
	background: #b9c9ec;
}

.settings {
	position: fixed;
	padding: 10px;
	right: 0;
	bottom: 0;
	z-index: 20;
	display: flex;
	flex-direction: column-reverse;
}
.navbar-nav {
	flex-direction: row;
}

$nav-height: 64px;

#login-form-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - #{$nav-height});
	background: linear-gradient(45deg, transparentize(#4b8dde, 0.5), transparentize(#4b8dde, 0.9));
	form {
		max-width: 500px;
		padding: 2rem;
		width: 100%;
		text-align: left;
		.logo {
			height: 50px;
			width: auto;
		}
		label {
			font-weight: bold;
			color: transparentize($color: #000000, $amount: 0.3);
		}
		input {
			background: transparentize($color: white, $amount: 0.6);
			border: unset;
			&:hover,
			&:active,
			&:focus {
				box-shadow: none;
				// border-bottom: 2px solid blue;
			}
		}
		.message {
			display: block;
			text-align: center;
			margin: auto;
			background: transparentize($color: red, $amount: 0.9);
			border-radius: 5px;
			padding: 0.2rem;
			font-size: 1.1rem;
			color: transparentize($color: red, $amount: 0.3);
			font-weight: bold;
			// text-shadow: 1px 1px 10px white;
		}
	}
	.copyright {
		font-size: 0.8rem;
		color: transparentize($color: black, $amount: 0.4);
	}
}

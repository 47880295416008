.btn-circle {
	border-radius: 50%;
}
.btn-40 {
	width: 40px;
	height: 40px;
}
.btn-30 {
	width: 30px;
	height: 30px;
}

.navbar {
	height: $nav-height;
}

.nav-link {
	font-size: 1rem;
}
.navbar-brand {
	img {
		width: auto;
		height: 30px;
	}
}

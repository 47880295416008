// $animation-duration:.3s;
@keyframes slide-left {
	0% {
		right: -100%;
		opacity: 0.2;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}
.detail-card {
    position: sticky;
    top: 1rem;
	// opacity: 0;
	// position: relative;
	// right: -100%;
	// animation: slide-left $animation-duration forwards;
}